import React, { Component } from 'react';
import moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ButtonHeight = {
    height: '100%',
}
const dateFormat = "DD-MMM-YYYY";
class CircularFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: `/api/circulars/type`,
            selectType: [],
            circularType: '',
            customtodate: null,
            customfromdate: null,
            fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            validationfrom: '',
            validationTo: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format("DD-MMM-YYYY"),
            callback: props.callback,
            resetcallback: props.resetcallback,
            linksarray: [
                {
                    id: "1D",
                },
                {
                    id: "1W",
                },
                {
                    id: "1M",
                },
                {
                    id: "3M",
                },
                {
                    id: "6M",
                },
                {
                    id: "1Y",
                },
                {
                    id: "ALL"
                },
                {
                    id: "Clear",
                }
            ],
            activeLink: "ALL",
            searchCircularKey: '',
            filterFromDt: "01-01-2017",
            filterToDt: moment().utc().utcOffset("+05:30").format(dateFormat),
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.customFilter = this.customFilter.bind(this);
        this.reset = this.reset.bind(this);
        this.searchCircular = this.searchCircular.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.validateFromToDate = this.validateFromToDate.bind(this);

    };
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadCircularTypes(this.state.api);
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChange = (date) => {
        this.setState({
            customfromdate: moment(date, dateFormat).format("DD-MMM-YYYY"),
        }, () => {
            this.validateFromToDate();
        });
    }
    loadCircularTypes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((response) => {
                //console.log(response);
                this.setState({
                    selectType: response.result,
                })
            })
            .catch((e) => { console.log("error in fetching circular category types", e) })
    }
    validateFromToDate = () => {
        if (moment(this.state.customtodate, dateFormat) < moment(this.state.customfromdate, dateFormat)) {
            this.setState({ validationfrom: "From date should not be after to date" })
        }
        else {
            this.setState({ validationfrom: "" })
        }
    }
    handleToDateChange = (date) => {
        this.setState({
            customtodate: moment(date, dateFormat).format("DD-MMM-YYYY")
        }, () => {
            this.validateFromToDate();
            this.setState({ maxFromDate: this.state.customtodate })
        });
    }

    handleClick(id) {
        this.setState({
            activeLink: id,
        }, function () {
            this.setState({
                maxFromDate: moment().utc().utcOffset("+05:30").format("DD-MMM-YYYY"),
            })
            switch (id) {
                case "1D":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat),
                    }, () => {
                        this.filter();
                    });

                    break;
                case "1W":
                    this.setState({
                        fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
                    }, () => {
                        this.filter();
                    });
                    break;
                case "1M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'months').format(dateFormat),
                    }, () => {
                        this.filter();
                    });
                    break;
                case "3M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat),
                    }, () => {
                        this.filter();
                    });
                    break;
                case "6M":
                    this.setState({
                        fromdate: moment(new Date()).subtract(6, 'months').format(dateFormat),
                    }, () => {
                        this.filter();
                    });
                    break;
                case "1Y":
                    this.setState({
                        fromdate: moment(new Date()).subtract(1, 'year').format(dateFormat),
                    }, () => {
                        this.filter();
                    });
                    break;
                case "ALL":
                    this.setState({
                        fromdate: "01-01-2017",
                    }, () => {
                        this.filter();
                    });
                    break;
                case "Clear":
                    this.reset();
                    break;

                default:
                    break;
            }
        })
    }

    filter() {
        this.setState({
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            customtodate: null,
            customfromdate: null,
            filterFromDt: this.state.fromdate,
            filterToDt: this.state.todate
        }, () => {
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.circularType.split('&').join('%26'), this.state.searchCircularKey);
        });
    }
    customFilter() {
        if (this.state.customfromdate && !this.state.customtodate) {
            this.setState({
                validationTo: "Please Select To Date"
            })
        }
        else if (!this.state.customfromdate && this.state.customtodate) {
            this.setState({
                validationfrom: "Please Select From Date"
            })
        }
        else if (this.state.customfromdate && this.state.customtodate && moment(this.state.customtodate, dateFormat) >= moment(this.state.customfromdate, dateFormat)) {
            this.setState({
                validationTo: '',
                validationfrom: '',
                activeLink: "",
                filterFromDt: this.state.customfromdate,
                filterToDt: this.state.customtodate
            },()=>{
                this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                    this.state.circularType.split('&').join('%26'), this.state.searchCircularKey);
            })
            
        }
        else { }
    }
    reset() {
        this.setState({
            fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat),
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            customtodate: null,
            customfromdate: null,
            validationTo: '',
            validationfrom: '',
            activeLink: "1W",
            searchCircularKey: '',
            circularType: '',
            maxFromDate: moment().utc().utcOffset("+05:30").format("DD-MMM-YYYY"),
            filterFromDt: moment(new Date()).subtract(7, 'days').format(dateFormat),
            filterToDt: moment().utc().utcOffset("+05:30").format(dateFormat),
        })
        this.state.resetcallback();
    }
    searchCircular(e) {
        
        this.setState({
            searchCircularKey: e.target.value
        }, () => {
           // console.log("rrrrrrrrrrrrrrrr",this.state.searchCircularKey)
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.circularType.split('&').join('%26'), this.state.searchCircularKey);
                // console.log("-----------")
        })
    }
    onSelectCategory(e) {
        // console.log("-----------")
        this.setState({ circularType: e.target.value }, () => {
            this.state.callback(this.state.filterFromDt, this.state.filterToDt,
                this.state.circularType.split('&').join('%26'), this.state.searchCircularKey);
                // console.log("-----------")
        })
    }
    render() {
        return (
            <>
                {/* <div className="row"> */}
                    <div className="filterdata">
                        {this.state.linksarray.map((links, index) => {
                            return <div key={index} data-val={links.id} onClick={() => this.handleClick(links.id)} className={(links.id === this.state.activeLink ? "days active" : "days")}>
                                {links.id}</div>
                        })}
                    </div>
                {/* </div> */}
                <div className="row corporate-filter-fields">
                    <div className="col-md-3 cofilter-padding">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={this.onSelectCategory} value={this.state.circularType}
                            displayEmpty>

                            <MenuItem value="" disabled>Category</MenuItem>

                            {this.state.selectType.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-md-3 cofilter-padding">
                        <div className="input-group colorsearchbar">

                            <input title="Search by Circular No or Keyword" type="text" className="circular-search form-control" placeholder="Search by Circular No or Keyword"
                                value={this.state.searchCircularKey}
                                onChange={this.searchCircular} autoComplete="off" />
                            <div className="input-group-append">

                                <span className="searchgroupicon"><i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker placeholder="From" variant="inline" format="dd-MM-yyyy" margin="normal" views={["year", "month", "date"]} openTo="date"
                                    id="fromDate" disableFuture={true} value={this.state.customfromdate} onChange={this.handleFromDateChange}
                                    KeyboardButtonProps={{ "aria-label": "from_date_button" }}
                                    autoOk={true}
                                    helperText={
                                        this.state.validationfrom !== "" ?
                                            this.state.validationfrom : null
                                    }
                                    maxDate={this.state.maxFromDate}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    
                    <div className="col-md-2 cofilter-padding">
                        <div className="customize-input-group-date customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableFuture={true} placeholder="To" variant="inline" format="dd-MM-yyyy" margin="normal" views={["year", "month", "date"]} openTo="date"
                                    id="toDate" maxDate={new Date()} value={this.state.customtodate} onChange={this.handleToDateChange}
                                    KeyboardButtonProps={{ "aria-label": "to_date_button" }}
                                    autoOk={true}
                                    helperText={
                                        this.state.validationTo !== "" ?
                                            this.state.validationTo : null
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div>
                    <div className="col-md-1 cofilter-padding">
                        <button className="btn btn-orange full-width cofilter-btn" style={ButtonHeight} onClick={this.customFilter}>Go</button>
                    </div>
                    {/* <div className="col-1 cofilter-padding">
                        <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
                    </div> */}
                </div>
            </>
        );
    }
}
export default CircularFilters;
