import React, { Component, useRef } from "react";
import "../../public/css/header-menu.scss";
import ifscwhitelogo from "../../public/images/NSEIFSC-Logo4_Final.png";
import GlobalSearch from "./global-search";
import Ticker from "./ticker";
import NavigationBar from "./menu-navigation-bar";
import { ReactComponent as HomeIcon } from "../../public/images/home.svg";
import { parseAsHtml } from "./util";
import { Select, MenuItem } from "@material-ui/core";
import SelectLanguage from "./selectLanguage";
import { ReactComponent as FormIcon } from '../../public/images/form-icon.svg';
import { BiPhoneCall } from "react-icons/bi";
import { ReactComponent as CircularFormIcon } from '../../public/images/circular-icon.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertApi: `/api/contents/alert`,
      breadcrumTitle: "",
      contentTitle: "",
      breadCrum: [],
      showBreadcrum: true,
      notfound: false,
      message: "",
      showAlert: false,
      activeMenu: "",
      breadCrumImg: [],
      screentop: "top",
      activePage:"",
      links: [],
       };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    console.log("Fruit Selected!!");
    this.setState({ fruit: e.target.value });
  }
  
  componentDidMount() {
    try {
      this._isMounted = true;
      this.loadHeaderLinks();
      //this.showalert(this.state.alertApi);

      //Scroll to top Code 
      window.addEventListener("scroll", () => {
        let screentop = "down";
        if (window.scrollY === 0) {
          screentop = "top";
        }
        this.setState({ screentop });
      });
    } catch (e) {
      console.log(e);
    }
  }

  loadHeaderLinks() {
    const menuUrl = `/api/contents/footer-links`;
    fetch(menuUrl)
        .then(resp => resp.json())
        .then((data) => {
            this.setState({
                links: data[0].items
            })
        })
        .catch((e) => {
            console.log("error in fetching footer links content", e)
        });
}


getIcon = (linkName, id) => {
  if(linkName.toLowerCase().includes("circulars")) {
   return <CircularFormIcon id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("notif")) {
   return <InfoOutlinedIcon id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("holiday")) {
   return  <FormIcon id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("website")) {
   return  <FormIcon id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("contact")) {
   return  <BiPhoneCall id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("copyright")) {
   return  <FormIcon id={id + "_icon"} />
  }
  else if(linkName.toLowerCase().includes("membership")) {
    return  <FormIcon id={id + "_icon"} />
   }
   else if(linkName.toLowerCase().includes("reports")) {
    return  <FormIcon id={id + "_icon"} />
   }
else if(linkName.toLowerCase().includes("link")) {
            return  <FormIcon id={id + "_icon"} />
            }

 else {
      return ""
  }
}

  //Set breadcrumb details of page
  updateTitle(breadcrumTitle, contentTitle, breadCrum, breadCrumImg) {
    breadcrumTitle = breadcrumTitle.trim();
    contentTitle = contentTitle.trim();
    this.setState({
      breadcrumTitle,
      contentTitle,
      breadCrum,
      breadCrumImg: breadCrumImg || [],
    });
  }

  //Show website alert from CMS based on Yes/No
  // showAlert() {
  //   fetch(this.state.alertApi)
  //     .then((resp) => resp.json())
  //     .then((response) => {
  //       this.setState({
  //         message: response.data[0],
  //         showAlert: removePtag(response.showAlert).toLowerCase().includes("yes")
  //           ? true
  //           : false,
  //       });
  //     })
  //     .catch((e) => {
  //       console.log("error in homepage alert", e);
  //     });
  // }

  //Hide breadcrum on Not Found Page
  hideBreadCrum() {
    this.setState({ showBreadcrum: false });
  }
  showHeaderWrapBackground() {
    this.setState({ notfound: true });
  }

  //Set Highlight Css on Active Menu
  activeMenu(menukey,pagekey) {
    this.setState({ activeMenu: menukey, activePage:pagekey || '' });
  }
  render() {

    // const data = useRef();

    // const handleLanguage = () => {
    //   console.log(data.current.value)
    // }
    return (
      <>
   
        <div
          style={
            this.state.breadCrumImg[0] !== undefined
              ? { backgroundImage: `url(${this.state.breadCrumImg[0].url})` }
              : { backgroundImage: `` }
          }
          className={
            this.state.showBreadcrum || this.state.notfound
              ? "header-wrap"
              : "header-home-wrap " + this.state.screentop
          }
        >
          
          
        {/* ) : (
          <></>
        )} */}
           
         <div role="banner">
         <div className="footerbg col-lg-12">
                    <div className="row">
                        <div className="col-lg-12" style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <ul className="footerstrip" style={{padding:"6px 0",fontWeight:"650"}}>
                                {this.state.links.map((link, idx) => {
                                    return idx < 7 ? <li key={link.key}>

                                        {/* Get Icons for links */}
                                        {this.getIcon(link.name, link.key)}
                                        
                                        <a aria-label={link.name + " Quick link"} style={{fontSize:"12px", textDecoration:"none"}} 
                                            href={link.url} title={link.name}>
                                            {link.name}</a>
                                    </li> : ""
                                })}
                            </ul>
                        </div>
                        
                    </div>
                </div>

          <div className="header-overlay pt-2">
            <div className="container-fluid">
              <div className="ifsc-logo-header row justify-content-between align-items-center pb-1 ">
                {/* NSE Logo */}
                <div className="col-lg-3 col-md-3 w-auto"  style={{display:'flex'}}>
                  <div className="row">
                    <a href="/#">
                      <img
                        src={ifscwhitelogo}
                        className="logo"
                        alt="logo"
                        width="140"
                        height="84"
                      />
                    </a>
                  </div>
                </div>
                {/* Global Search Func */}
                <div className="col-lg-6 col-md-5 w-auto">
                  <GlobalSearch />
                </div>
                {/* Ticker */}
                <div className=" col-md-4">
                  <Ticker />

	<div className="" style={{display:"flex"}}>
  {/* <div className=""><a href="#" target="_blank" class="connect-2-btn flash-buttons">NORP<span className="tooltiptext">TOOLTIP</span></a></div> */}
  <div className="tooltip-container">
    <a href="https://www.nseicc.com/otc" target="_blank" className="connect-2-btn flash-buttons">
      NORP
    </a>
    <span className="tooltiptext">NSEICC OTC Reporting Platform</span>
  </div>
		<a href="https://www.nseicc.com/c2n" target="_blank" class="connect-2-btn flash-buttons">Connect2NSE</a>
    <a href="/membership/membership-forms" class="connect-2-btn flash-buttons">Become A Member</a>
		</div>

                </div>
              </div>
            </div>
          </div>
          </div>

          {/* Menubar */}  
          <NavigationBar websiteLanguage= {localStorage.getItem("websiteLanguage")} activePage={this.state.activePage} activeMenu={this.state.activeMenu} />
          </div>

          {/* Breadcrumb Section */}  
          {this.state.showBreadcrum && (
            <section id="sectiontwo" style={
              this.state.breadCrumImg[0] !== undefined
                ? { backgroundImage: `url(${this.state.breadCrumImg[0].url})` }
                : { backgroundImage: `` }
            } role="navigation" aria-label="breadcrumb">
              <div className="col-lg-12 breadcrumb-row">
                

                <ol className="breadcrumb">

                  <li className="breadcrumb-item">
                    <a href="/" title="Home">
                      <HomeIcon className="action" />
                      Home
                    </a>
                  </li>
                  {/* {
                        <li className="breadcrumb-item">{ JSON.stringify(this.state.breadCrum.length>0 && this.state.breadCrum[0]["field_breadcrumb"][0])}</li>
                         } */}
                  {typeof this.state.breadCrum != undefined &&
                    this.state.breadCrum.length > 0 &&
                    typeof this.state.breadCrum[0]["field_breadcrumb"] != undefined &&
                    this.state.breadCrum[0]["field_breadcrumb"].length > 0 &&
                    this.state.breadCrum[0]["field_breadcrumb"].map((items, idx) => {
                      return (
                        <li key={idx} className="breadcrumb-item">
                          <a href={items.uri} title={items.title} aria-label={"Breadcrum url"+items.title}>{items.title}</a>
                        </li>
                      );
                    })}
                </ol>
                <h1>{parseAsHtml(this.state.breadcrumTitle)}</h1>
              </div>
            </section>
          )}
        
      </>
    );
  }
}

export default Header;
